@mixin Team() {
    .team-subtitle {
        margin-bottom: 2rem;
    }

    .team-title {
        margin-bottom: 5.75rem;
        width: 50%;

        @include media-breakpoint-down(md) {
            width: 80%;
		}
    }

    .team-circles {
        z-index: -1;
        position: fixed;
        top: -5%;
        right: 0;
        width: 8rem;

        @include media-breakpoint-down(md) {
			top: 0;
		}
    }
    .team-dots {
        position: fixed;
        top: 25%;
        width: 100%;
        left: 0;
        z-index: -1;
    }
    .team-form-top {
        position: fixed;
        top: 23%;
        left: 11%;
        z-index: -1;

        @include media-breakpoint-down(md) {
			display: none;
		}
    }
    .team-form-bot {
        z-index: -1;
        position: fixed;
        bottom: 2%;
        right: 11%;
        transform: rotate(90deg);

        @include media-breakpoint-down(md) {
			display: none;
		}
    }

	.team {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .team-box {
            width: calc(50% - 2rem);
            margin-bottom: 3rem;
            display: flex;
            align-items: center;
            background-color: #000;

            .team-box-img {
                width: 45%;
                height: 100%;
                object-fit: cover;
            }

            .team-box-text {
                width: 55%;
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
    
                .team-box-text-title {
                    font-size: 1.125rem;
                    color: #fff;
                }
                .team-box-text-label {
                    font-size: 1.125rem;
                    font-weight: 700;
                    color: #fff;

                    .colored {
                        color: $primary;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
			.team-box {
                width: 100%;
                height: 26.25rem;
            }

            .team-box-text {
                height: 80% !important;
                padding: 2.5rem !important;
            }
		}

        @include media-breakpoint-down(xs) {
            .team-box-text {
                height: 100% !important;
                padding: 1rem !important;
            }
            .team-box-text-title {
                font-size: 0.9rem !important;
            }
            .team-box-text-label {
                font-size: 0.9rem !important;
            }
		}
    }

    @include media-breakpoint-down(xs) {
        .team-title {
            font-size: 2rem !important;
        }
    }
}
