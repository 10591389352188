@mixin Experience() {
	.experience-wrapper {
		position: relative;
		padding: 8rem 0;

		.experience-shapes {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 34rem;
			height: auto;
			transform: translateX(14.5%);

			#dot {
				@keyframes ideas-dot-animation {
					0% {
						transform: translateY(-0.45rem);
					}
					50% {
						transform: translateY(.45rem);
					}
					100% {
						transform: translateY(-0.45rem);
					}
				}

				animation-name: ideas-dot-animation;
				animation-duration: 3s;
				animation-iteration-count: infinite;
			}
		}

		.experience-subtitle {
			margin-bottom: 2.6875rem;
		}

		.experience-title {
			margin-bottom: 1.3125rem;
			font-weight: bold;
			width: 80%;
		}

		.experience-text-big {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: 3rem;
			color: #4cc3c6;
			max-width: 34.375rem;
		}

		.experience-text {
			font-size: 1.125rem;
			line-height: 2rem;
			max-width: 35rem;
		}

		@include media-breakpoint-down(md) {
			padding: 12rem 0;

			.experience-shapes {
				display: none;
			}

			.experience-title {
				width: 100%;
				font-size: 3rem;
			}
			.experience-text-big {
				font-size: 1.3rem;
				margin-bottom: 2rem;
			}
		}
		@include media-breakpoint-down(xs) {
			padding: 8rem 0;
			
			.experience-title {
				font-size: 2rem !important;
			}
		}
	}
}
