@mixin CaseStudies() {
	.case-studies-wrapper {
		padding-top: 5rem;
		padding-bottom: 6.25rem;

		.case-studies-subtitle {
			margin-bottom: 2rem;
		}

		.title {
			margin-bottom: 5rem;
		}

		.slider {
			padding-left: 10rem;
			margin-bottom: 10rem;
		}

		.case-studies-item {
			.item-background {
				background-color: #232323;
    			display: flex;
    			justify-content: center;
    			align-items: center;
    			height: 24.5rem;
				margin-bottom: 2.25rem;
				padding: 30px 50px;
			}
			.item-title {
				font-weight: 700;
				font-size: 2.625rem;
				margin-bottom: 2rem;
			}
			.item-text {
				margin-bottom: 1rem;
				font-size: 1.375rem;
			}
			.item-btn {
				text-transform: unset;
				font-size: 1.375rem;
				font-weight: bold;
				text-decoration: none;
				border: none;
    			background-color: transparent;
				transition: 0.2s ease-out
			}.item-btn:hover {
				transform: scale(107%);
			}
			.item-arrow-right {
				width: 1.2rem;
				height: 0.75rem;
			}
		}

		.casestudies-dots {
			width: 100vw;
    		bottom: 0;
    		left: 0;
    		transform: rotate(180deg);
    		width: 100%;
    		z-index: -1;
    		position: fixed;
    		left: 0;
		}

		@include media-breakpoint-down(md) {
			.slider {
				padding-left: 4rem;
			}
			.slider__case-studies {
				padding: 20px;
			}
		}

		@include media-breakpoint-down(sm) {
			padding-top: 8rem;
			padding-bottom: 4rem;

			.case-studies-item {
				.item-background {
					height: 16.5rem;
				}
			}

			.testimonials-subtitle {
				margin-bottom: 1.5rem;
				transform: translateY(0px);
				opacity: 1;
		
				&:before {
					width: 40px;
				}
			}

			.title {
				font-size: 2rem;
				margin-bottom: 3rem;
			}

			.slider {
				width: 100%;
				padding-left: 0;
				margin-bottom: 5rem;

				.testimonials-item {
					padding: 0 25px;
				}
			}
		}
	}
}
