@mixin Testimonials() {
	.testimonials-wrapper {
		position: relative;
		padding-bottom: 4.75rem;

		.testimonials-title {
			margin-bottom: 5.75rem;
		}

		.testimonials {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 4.5rem;

			.testimonial {
				padding-bottom: 1.5rem;

				.testimonial-text {
					font-size: 1.25rem;
    				font-weight: 100;
				}
				.testimonial-author {
					font-size: 1.125rem;
					font-weight: 700;
				}
				.testimonial-icon {
					width: 24rem;
					margin-bottom: 1.125rem;
				}
			}
		}

		@include media-breakpoint-down(xs) {
			padding-bottom: 8rem;

			.testimonials-title {
				font-size: 2rem !important;
			}

			.testimonials {
				grid-template-columns: 1fr;
				gap: 3.5rem;

				.testimonial {
					.testimonial-title {
						font-size: 1.5rem;
					}
				}
			}

			.testimonials-circles {
				width: 5rem;
			}
		}
	}
}
