@import '../mixins';
@import '../variables';
@import './home/testimonials';
@import './home/careers';
@import './home/experience';
@import './home/intro';
@import './home/case-studies';
@import './home/contact-modal';
@import './home/team';

.web3-wrapper {
  .bottom-left-lines {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 26.9375rem;
    height: 19.875rem;
  }
  
  @include Intro();
  @include Testimonials();
  @include Experience();
  @include CaseStudies();
  @include Careers();
  @include ContactModal();
  @include Team();
}